import { NexusIcon } from '@nexus/react';
import React from 'react';

interface Props {
    src: string;
    alt?: string;
    content?: string;
    size?: 'lg' | 'md' | 'sm' | 'xl' | 'xs';
    className?: string;
    onClick?: () => void;
}
const IconComponent: React.FC<Props> = ({ src, alt = '', content = '', size = 'md', className = '', onClick }) => {
    return (
        <NexusIcon
            className={className}
            data-testid='nexus-icon'
            src={src}
            alt={alt}
            content={content}
            size={size}
            onClick={onClick && onClick}
        ></NexusIcon>
    );
};

export default IconComponent;
