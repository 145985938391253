import { DownloadApiFactory } from 'app/api/dra-bl-service';
import { blApiConfig } from 'constants/apiConstants';

import { createFileFromResponse } from 'services/services';

const downloadApiFactory = DownloadApiFactory(blApiConfig);
export const downloadRisks = async (filters) => {
    try {
        const res = await downloadApiFactory.downloadRisks(
            filters.sectorIds,
            filters.businessUnitIds,
            filters.regionIds,
            filters.categoryIds,
            filters.controlIds,
            filters.searchTerm,
        );
        createFileFromResponse(res, 'risks.csv');
        return res;
    } catch (e) {
        throw e;
    }
};

export const downloadProcesses = async (filters) => {
    try {
        const res = await downloadApiFactory.downloadProcesses(
            filters.sectorIds,
            filters.businessUnitIds,
            filters.regionIds,
            filters.categoryIds,
            filters.controlIds,
            filters.searchTerm,
        );
        createFileFromResponse(res, 'processes.csv');
        return res;
    } catch (e) {
        throw e;
    }
};
