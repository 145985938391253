import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import './App.scss';
import store, { persistor } from '../store';
import ErrorBoundary from './components/errorboundry/errorBoundary';
import AppRoutes from './routes';
import LoaderComponent from './components/nds/loader/loader';
import { setNonce } from '@nexus/core/loader';
setNonce('07062024');

const App = () => {
    return (
        <React.StrictMode>
            <ErrorBoundary>
                <Suspense fallback={<LoaderComponent show={true} fullscreen={true} />}>
                    <div data-testid='nexus-app'>
                        <Provider store={store}>
                            <PersistGate persistor={persistor}>
                                <AppRoutes />
                            </PersistGate>
                        </Provider>
                    </div>
                </Suspense>
            </ErrorBoundary>
        </React.StrictMode>
    );
};

export default App;
