import { REACT_APP_AUTH_TYPE } from '../config/settings';
export const providerTypes: { [id: string]: string } = {
    AD: 'AD',
    ADB2C: 'ADB2C',
    AD_SPA: 'AD_SPA',
    COGNITO: 'COGNITO',
    OAUTH: 'OAUTH',
    OKTA: 'OKTA',
    TOKEN: 'TOKEN',
};
export const SSO_PROVIDERS = [providerTypes.AD_SPA, providerTypes.OKTA, providerTypes.AD, providerTypes.OAUTH];

/**
 * Returns the configured provider type, defaulting to AD if nothing is configured.
 */
export const getConfiguredProviderType = () => {
    const configured: string = REACT_APP_AUTH_TYPE || '';

    return providerTypes[configured.toUpperCase()] || providerTypes.AD;
};

/**
 * Returns true if the provided type is the configured provider type.
 */
export const isConfiguredProviderType = (type: string) => {
    const configured = getConfiguredProviderType();
    if (!type) {
        return false;
    }

    return type.toUpperCase() === configured;
};

export interface IAdSpaAuthProps {
    callBack?: Function;
}

export interface IOktaAuthProps {
    callBack?: Function;
}

export interface ICognitoAuthProps {
    clientId: string;
    poolId: string;
    callBack?: Function;
    email: string;
    password: string;
}

export interface ICognitoSilentAuthProps {
    email: string;
    callBack?: Function;
}

export interface IoktaAuthProps {
    callBack?: Function;
}
