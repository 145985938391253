import { NexusAccordion, NexusAccordionTrigger, NexusAccordionContent } from '@nexus/react';
import React, { useState, ReactNode } from 'react';

interface Props {
    accordionContent: {
        info: any;
        isOpen: boolean;
        title: ReactNode;
    };
    size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    align?: 'start' | 'center' | 'end';
    children?: React.ReactNode;
}

const AccordionComponent: React.FC<Props> = ({ accordionContent, size, align, children }) => {
    const [accordionData, setValue] = useState<any>(accordionContent);

    const handleToggle = (toggleInput: boolean): void => {
        const accordionInfo = { ...accordionData };
        accordionInfo.isOpen = toggleInput;
        setValue(accordionInfo);
    };
    return (
        <>
            <NexusAccordion
                open={accordionData.isOpen}
                onToggleEvent={() => handleToggle(!accordionData.isOpen)}
                size={size}
                data-testid='nexus-accordion'
            >
                <NexusAccordionTrigger align={align || 'start'}>
                    <h1 className='nexus-body'>{accordionData.title}</h1>
                </NexusAccordionTrigger>
                <NexusAccordionContent>
                    {accordionData.info}
                    {children}
                </NexusAccordionContent>
            </NexusAccordion>
        </>
    );
};

export default AccordionComponent;
