import { AuthContainer, UnAuthContainer } from '../containers';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import React from 'react';
import Home from 'app/views/home';
import Inventory from 'app/views/inventory';
import ProcessDetails from 'app/views/processDetails/processDetails';

const LoginPage = React.lazy(() => import('app/views/login/loginPage'));
const Logout = React.lazy(() => import('app/views/logout/logout'));
const PageNotFound = React.lazy(() => import('app/views/404/404'));
const ProfilePage = React.lazy(() => import('app/views/profile/profile'));
const RiskDetails = React.lazy(() => import('app/views/risk_details/riskDetail'));

const routes = [
    {
        auth: true,
        element: <ProfilePage />,
        path: '/profile',
    },
    {
        auth: false,
        element: <LoginPage />,
        path: '/',
    },
    {
        auth: true,
        element: <Home />,
        path: '/home',
    },
    {
        auth: true,
        element: <RiskDetails />,
        path: '/risk-detail/:id',
    },
    {
        auth: true,
        element: <ProcessDetails />,
        path: '/process-detail/:id',
    },
    {
        auth: true,
        element: <Inventory />,
        path: '/inventory/:tab?',
    },
];
const AppRoutes: React.FC = () => {
    return (
        <>
            <Router>
                <Routes>
                    {routes.map((route) => {
                        if (route.auth) {
                            return (
                                <Route
                                    key={route.path}
                                    path={route.path}
                                    element={<AuthContainer>{route.element}</AuthContainer>}
                                />
                            );
                        } else {
                            return (
                                <Route
                                    key={route.path}
                                    path={route.path}
                                    element={<UnAuthContainer>{route.element}</UnAuthContainer>}
                                />
                            );
                        }
                    })}
                    <Route path='/logout' element={<Logout />} />
                    <Route
                        path='*'
                        element={
                            <AuthContainer>
                                <PageNotFound />
                            </AuthContainer>
                        }
                    />
                </Routes>
            </Router>
        </>
    );
};

export default AppRoutes;
