import React from 'react';

import {
    NexusIcon,
    NexusFooter,
    NexusFooterBottom,
    NexusFooterContent,
    NexusFooterColumn,
    NexusFooterContentTitle,
    NexusFooterLogo,
} from '@nexus/react';
import Logo from '@nexus/core/dist/assets/images/nexus-footer-logo.svg';
import { Link } from 'react-router-dom';

const inputData = {
    sticky: 'nexus-footer-sticky',
    hidden: 'nexus-visually-hidden',
};

const FooterComponent: React.FC = () => (
    <NexusFooter data-testid='nexus-footer' className='footer'>
        <NexusFooterLogo data-testid='nexus-footer-logo'>
            <Link to='#'>
                <NexusIcon src={Logo} />
                <span className={inputData.hidden}>Home</span>
            </Link>
        </NexusFooterLogo>
        <NexusFooterColumn data-testid='nexus-footer-column-1'>
            <NexusFooterContentTitle>
                <p>Location</p>
            </NexusFooterContentTitle>
            <NexusFooterContent>
                <Link to=''>Amsterdam</Link>
            </NexusFooterContent>
            <NexusFooterContent>
                <Link to=''>Dordrecht</Link>
            </NexusFooterContent>
        </NexusFooterColumn>
        <NexusFooterColumn data-testid='nexus-footer-column-2'>
            <NexusFooterContentTitle>
                <p>title</p>
            </NexusFooterContentTitle>
            <NexusFooterContent>
                <Link to=''>Amsterdam</Link>
            </NexusFooterContent>
            <NexusFooterContent>
                <Link to=''>Dordrecht</Link>
            </NexusFooterContent>
            <NexusFooterContent>
                <Link to=''>Eindhoven</Link>
            </NexusFooterContent>
        </NexusFooterColumn>
        <NexusFooterBottom data-testid='nexus-footer-column-bottom'>
            <span>Copyright&#169; Ernst & Young, LLC. All rights reserved</span>
        </NexusFooterBottom>
    </NexusFooter>
);

export default FooterComponent;
