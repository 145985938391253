import React from 'react';
import arrow from '@nexus/core/dist/assets/icons/action/ic_arrow_drop_up_24px.svg';
import downArrow from '@nexus/core/dist/assets/icons/action/ic_arrow_drop_down_24px.svg';
import { NexusIcon } from '@nexus/react';
import './chipArrow.scss';
function ChipArrow({ bgColor, color, text, arrowDirection = 'UP', disableArrow = false, chipType }: any) {
    return (
        <div
            className={`chip ${chipType === 'Controls' ? 'controls' : ''}`}
            style={{
                backgroundColor: bgColor,
                color,
            }}
        >
            <div className='chip-risk-level'>{text} </div>
            {!disableArrow && (
                <div className='arrow'>
                    <NexusIcon src={arrowDirection === 'UP' ? arrow : downArrow} />
                </div>
            )}
        </div>
    );
}

export default ChipArrow;
