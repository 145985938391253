/* eslint-disable camelcase */
import queryString from 'query-string';
import { nanoid } from 'nanoid';
import { REACT_APP_AUTH_URL, REACT_APP_CLIENT_ID, REACT_APP_OAUTH_BASE_URL } from '../../config/settings';
import { generateCodeVerifierAndChallenge, getNewAccessToken } from '../authHelper';

// Configuration params for AD azure requests
const authority = REACT_APP_OAUTH_BASE_URL;
const client_id = REACT_APP_CLIENT_ID;
const redirect_uri = REACT_APP_AUTH_URL;
const response_type = 'code';
const scope = REACT_APP_CLIENT_ID + '/.default';

/**
 * Authenticate by hitting an authorize endpoint. Will redirect to the url and return the request back
 * to the calling/redirect uri.
 */
export const authenticate = (search: string | undefined = ''): void => {
    const nonce = nanoid(32);
    const state =
        typeof search === 'string' && search.length
            ? btoa(
                  queryString.stringify({
                      n: `${Date.now()}-${nonce.slice(0, -2)}`,
                      ...queryString.parse(search),
                  }),
              )
            : nanoid(32);
    // nonce can be used to verify the returned token..

    // Generate authentication URL

    generateCodeVerifierAndChallenge().then((result: any) => {
        sessionStorage.setItem('code_verifier', result.codeVerifier);
        const params = queryString.stringify({
            client_id,
            code_challenge: result.codeChallenge,
            code_challenge_method: 'S256',
            redirect_uri,
            response_type,
            scope,
            state,
        });
        const authUrl = `${authority}/authorize?${params}`;
        window.location.assign(authUrl);
    });
};

/**
 * Authenticate silently by setting provided iframe's src to an authorize endpoint and providing an
 * authentication hint by way of email address.
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-implicit-grant-flow
 *
 * Uses publicly accessible static html page for the token redirect.
 */
export const authenticateSilently = async (refreshToken: string, dispatch: any) => {
    getNewAccessToken(refreshToken, dispatch);
};

/**
 * Logout session by hitting logout endpoint. Will redirect to the url and return the request back
 * to the calling/redirect uri.
 */
export const logout = (idToken: string) => {
    const params = queryString.stringify({
        client_id,
        id_token_hint: idToken,
        post_logout_redirect_uri: redirect_uri,
        resource: client_id,
    });
    const authUrl = `${authority}/logout?${params}`;
    // Attempt logout by navigating to authUrl with logout params
    window.location.assign(authUrl);
};
