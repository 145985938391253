// For more information on data-driven styles, see https://www.mapbox.com/help/gl-dds-ref/
export const dataLayer = {
    id: 'data',
    paint: {
        'fill-color': '#00000000', // defines color of the states
        'fill-opacity': 0.8, // changes the opacity
        'fill-outline-color': '#FFFFFF', // defines color for state outlines
    },
    type: 'fill',
};
