import './index.scss';
import cancel from '@nexus/core/dist/assets/icons/action/ic_close_24px.svg';
import { NexusIcon } from '@nexus/react';
import { useEffect, useState } from 'react';

function DrawerComponent({ title, children, onClose, open }: any) {
    const [expand, setExpand] = useState(open);
    const [hide, setHide] = useState(true);
    const [initialRender, setInitialRender] = useState(true);

    useEffect(() => {
        if (initialRender) {
            setInitialRender(false);
            setHide(true);
        } else {
            setHide(false);
            setExpand(open);
        }
    }, [open]);
    return (
        <div className={`drawer-container  ${hide ? 'hide' : expand ? 'open' : 'close'}`}>
            <div onClick={onClose} className='cancel-button'>
                <NexusIcon src={cancel} />
            </div>
            <div className='heading'>{title}</div>
            <div className='tab-section'>{children}</div>
        </div>
    );
}

export default DrawerComponent;
