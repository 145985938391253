import { useMemo } from 'react';
import * as d3 from 'd3';

type DataItem = {
    name: string;
    value: number;
    color: string;
};
type DonutChartProps = {
    width: number;
    height: number;
    data: DataItem[];
    innerRadius?: number;
    title?: string | number;
    border?: { color: string; width: number };
    className?: string;
};

const MARGIN = 30;

export const DonutChart = ({
    className = '',
    width,
    height,
    data,
    innerRadius = 80,
    title = '',
    border = { color: '', width: 0 },
}: DonutChartProps) => {
    const radius = Math.min(width, height) / 2 - MARGIN;

    const pie = useMemo(() => {
        const pieGenerator = d3.pie<any, DataItem>().value((d) => d.value);
        return pieGenerator(data);
    }, [data]);

    const arcs = useMemo(() => {
        const arcPathGenerator = d3.arc();
        return pie.map((p) =>
            arcPathGenerator({
                endAngle: p.endAngle,
                innerRadius: innerRadius,
                outerRadius: radius,
                startAngle: p.startAngle,
            }),
        );
    }, [radius, pie]);

    return (
        <svg width={width} height={height} style={{ display: 'inline-block' }} className={className}>
            <g transform={`translate(${width / 2}, ${height / 2})`}>
                {arcs.map((arc: any, i) => {
                    return (
                        <path key={i} d={arc} fill={data[i].color} stroke={border.color} strokeWidth={border.width} />
                    );
                })}
            </g>
            <text className={'title'} x={width / 2} y={height / 2} textAnchor='middle' dominantBaseline='middle'>
                {title}
            </text>
        </svg>
    );
};
