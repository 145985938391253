/**
 * Returns button Index for keys
 */

export const getButtonIndex = (_index: number) => {
    return `button-${_index}`;
};

export const getUniqueKey = (index: number, key: string = 'key') => {
    return key + '-' + index;
};
