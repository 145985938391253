/* eslint-disable sort-keys */
import React, { useEffect, useState } from 'react';
import './index.scss';
import {
    addFilter,
    addMultipleFilters,
    removeFilter,
    removeMultipleFilters,
    resetFilters,
    searchProcess,
    searchRisk,
} from './redux/inventorySlice.reducer';
import { useDispatch, useSelector } from 'react-redux';
import {
    getInventoryBreadcrumb,
    getInventoryFilters,
    getProcessDataSelector,
    getRiskDataSelector,
    isInventoryScreenLoading,
} from './redux/inventorySelector';
import CustomTable from 'app/components/nds/customTable/customTable';
import { useTranslation } from 'react-i18next';
import InputComponent from 'app/components/nds/input/input';
import ButtonComponent from 'app/components/nds/button/button';
import BreadcrumbComponent from 'app/components/nds/breadcrumb/breadcrumb';
import BackArrow from '@nexus/core/dist/assets/icons/action/ic_arrow_left_alt_24px.svg';
import TabsComponent from 'app/components/nds/tabs/tabs';
import CardComponent from 'app/components/nds/card/card';
import { NexusCheckbox, NexusIcon } from '@nexus/react';
import downloadIcon from '@nexus/core/dist/assets/icons/action/ic_download_24px.svg';
import searchIcon from '@nexus/core/dist/assets/icons/action/ic_search_24px.svg';
import closeIcon from '@nexus/core/dist/assets/icons/action/ic_close_24px.svg';
import IconComponent from 'app/components/nds/icon/icon';
import { Chip } from 'app/components/ColorPalette';
import { ASC, DESC, EFFECTIVENESS_COLOURS, HOME_URL, INVENTORY_URL, RISK_LEVELS_COLORS } from 'constants/constants';
import ChipArrow from 'app/components/ColorPalette/chipArrow';
import FavComponent from './favComponent';
//import AccordianCheckboxList from './accordianCheckboxList';
import Filters from './filter';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { updateBreadcrumb } from '../risk_details/redux/riskDetailSlice.reducer';
import { downloadProcesses, downloadRisks } from './helper';
import LoaderComponent from 'app/components/nds/loader/loader';

const tabOption = [
    {
        isActive: false,
        title: 'Processes',
        value: <></>,
    },
    {
        isActive: true,
        title: 'Risks',
        value: <></>,
    },
    {
        isActive: false,
        title: 'Controls',
        value: <></>,
    },
];

function Inventory() {
    const dispatch = useDispatch();
    const { tab } = useParams();
    const [t] = useTranslation('lang');
    const [riskData, setRiskData] = useState([]);
    const [processData, setProcessData] = useState([]);
    const [tabOptions, setTabOptions] = useState(tabOption);
    const [selectedTab, setSelectedTab] = useState<any>();
    const navigate = useNavigate();
    const { results: riskDataResult, facets: riskDataFacets, page: riskDataPage } = useSelector(getRiskDataSelector);
    const {
        results: processDataResult,
        facets: processDataFacets,
        page: processDataPage,
    } = useSelector(getProcessDataSelector);
    const inventoryFilters = useSelector(getInventoryFilters);
    const breadcrumbData = useSelector(getInventoryBreadcrumb);
    const isLoading = useSelector(isInventoryScreenLoading);
    const [searchTerm, setSearchTerm] = useState<string>('');
    //pagination states
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(5);
    //sort states
    const [sortCol, setSortCol] = useState<string>('');
    const [sort, setSort] = useState<string>(DESC);
    // selected fiters diplay constants
    const totalFilters = Object.values(inventoryFilters[selectedTab?.title] || {}).flat().length;
    const MAX_VISIBLE_FILTERS = 5;
    let displayedFitlerCount = 0;

    const riskColDef = [
        {
            enableCheckBox: true,
            field: 'checkbox',
            id: '0',
            isSortable: false,
            label: '',
            minWidth: 50,
        },
        {
            field: 'id',
            id: '1',
            isSortable: true,
            label: <div>{t('inventoryPage.id')}</div>,
            minWidth: 80,
        },
        {
            field: 'name',
            id: '2',
            isSortable: true,
            label: <div>{t('inventoryPage.entityName')}</div>,
            minWidth: 300,
        },
        {
            field: 'inherentRiskRating',
            id: '4',
            isSortable: true,
            label: <div>{t('inventoryPage.inherentRisk')}</div>,
            minWidth: 165,
        },
        {
            field: 'effectiveness',
            id: '5',
            isSortable: true,
            label: <div>{t('inventoryPage.controls')}</div>,
            minWidth: 150,
        },
        {
            field: 'residualRiskRating',
            id: '6',
            isSortable: true,
            label: <div>{t('inventoryPage.residualRisk')}</div>,
            minWidth: 165,
        },
        {
            field: 'fav',
            id: '6',
            isSortable: false,
            label: <div>{t('')}</div>,
            minWidth: 50,
        },
    ];
    const processColDef = [
        {
            field: 'id',
            id: '1',
            isSortable: true,
            label: <div>{t('inventoryPage.id')}</div>,
            minWidth: 80,
        },
        {
            field: 'name',
            id: '2',
            isSortable: true,
            label: <div>{t('inventoryPage.entityName')}</div>,
            minWidth: 200,
        },
        {
            field: 'type',
            id: '2',
            isSortable: true,
            label: <div>{t('inventoryPage.type')}</div>,
            minWidth: 200,
        },
        {
            field: 'description',
            id: '2',
            isSortable: true,
            label: <div>{t('inventoryPage.description')}</div>,
            minWidth: 350,
        },
        {
            field: 'rating',
            id: '2',
            isSortable: true,
            label: <div>{t('inventoryPage.testing')}</div>,
            minWidth: 100,
        },
    ];

    const getIds = (data: any) => {
        return data.map((item: any) => item.id);
    };

    // get the tab from params
    useEffect(() => {
        if (tab) {
            setSelectedTab(tabOption.find((item) => item.title.toLowerCase() === tab) || tabOption[1]);
            const updatedTabOptions = tabOption.map((item) => {
                if (item.title.toLowerCase() === tab) {
                    return { ...item, isActive: true };
                } else {
                    return { ...item, isActive: false };
                }
            });
            setTabOptions(updatedTabOptions);
        } else {
            setSelectedTab(tabOption[1]);
        }
    }, [tab]);
    // get tab level data for risk / process / control
    useEffect(() => {
        if (selectedTab) {
            const sortData = sortCol && sort ? `${sortCol},${sort}` : '';
            switch (selectedTab?.title) {
                case 'Risks':
                    dispatch(
                        searchRisk({
                            page: currentPage,
                            size: pageSize,
                            sort: [sortData],
                            searchTerm,
                            sectorIds: [],
                            businessUnitIds: getIds(inventoryFilters[selectedTab?.title].businessUnit || []) || [],
                            regionIds: getIds(inventoryFilters[selectedTab?.title].regions || []) || [],
                            categoryIds: getIds(inventoryFilters[selectedTab?.title].categories || []) || [],
                            followed: getIds(inventoryFilters[selectedTab?.title].followed || []) || [],
                            controlIds: [],
                        }),
                    );
                    break;
                case 'Processes':
                    dispatch(
                        searchProcess({
                            page: currentPage,
                            size: pageSize,
                            sort: [sortData],
                            searchTerm,
                            sectorIds: [],
                            businessUnitIds: getIds(inventoryFilters[selectedTab?.title].businessUnit || []) || [],
                            regionIds: getIds(inventoryFilters[selectedTab?.title].regions || []) || [],
                            categoryIds: getIds(inventoryFilters[selectedTab?.title].categories || []) || [],
                            controlIds: [],
                        }),
                    );
                    break;
                case 'Controls':
                    break;
                default:
                    break;
            }
        }
    }, [currentPage, pageSize, sort, sortCol, searchTerm, inventoryFilters, selectedTab?.title]);

    // transform risk data to match the table columns
    useEffect(() => {
        const data = riskDataResult?.map((item: any) => {
            return {
                checkbox: (
                    <NexusCheckbox
                        data-testid='headerCheckBox-cep'
                        onClick={() => {}}
                        checked={false}
                        className={'checkbox-style'}
                    ></NexusCheckbox>
                ),
                effectiveness: (
                    <span
                        className='effectiveness'
                        style={{
                            backgroundColor: EFFECTIVENESS_COLOURS[item.effectiveness].bgColor,
                            borderColor: EFFECTIVENESS_COLOURS[item.effectiveness].borderColor,
                            color: EFFECTIVENESS_COLOURS[item.effectiveness].textColor,
                        }}
                    >
                        <span className='label'>{t(`inventoryPage.${item.effectiveness}`)}</span>
                    </span>
                ),
                fav: <FavComponent data={{ followed: item.followed, id: item.id }} />,
                id: (
                    <Link
                        className='risk-id'
                        to={`/risk-detail/${item.id}`}
                        onClick={() => {
                            dispatch(
                                updateBreadcrumb([
                                    { isEnabled: true, name: 'Dashboard', path: HOME_URL },
                                    { isEnabled: true, name: 'Inventory', path: INVENTORY_URL },
                                    { isEnabled: false, name: item.name },
                                ]),
                            );
                        }}
                    >
                        {12345}
                    </Link>
                ),
                inherentRiskRating: (
                    <Chip
                        className='risk-level-chip'
                        label={item.inherentRiskRating}
                        color={RISK_LEVELS_COLORS[Math.floor(item.inherentRiskRating)]}
                    />
                ),
                name: <span className='risk-name'> {item.name}</span>,
                residualRiskRating: (
                    <ChipArrow
                        text={item.residualRiskRating}
                        bgColor={RISK_LEVELS_COLORS[Math.floor(item.residualRiskRating)]}
                        color={item.residualRiskRating === 5 ? 'white' : 'black'}
                    />
                ),
            };
        });
        setRiskData(data);
    }, [riskDataResult]);
    // transform process data to match the table columns
    useEffect(() => {
        const data = processDataResult?.map((item: any) => {
            return {
                checkbox: (
                    <NexusCheckbox
                        data-testid='headerCheckBox-cep'
                        onClick={() => {}}
                        checked={false}
                        className={'checkbox-style'}
                    ></NexusCheckbox>
                ),
                id: (
                    <Link
                        className='risk-id'
                        to={`/process-detail/${item.id}`}
                        onClick={() => {
                            dispatch(
                                updateBreadcrumb([
                                    { isEnabled: true, name: 'Dashboard', path: HOME_URL },
                                    { isEnabled: true, name: 'Inventory', path: INVENTORY_URL },
                                    { isEnabled: false, name: item.name },
                                ]),
                            );
                        }}
                    >
                        {12345}
                    </Link>
                ),
                name: <span className='process-name'> {item.name}</span>,
                rating: (
                    <ChipArrow
                        text={item.rating}
                        bgColor={RISK_LEVELS_COLORS[Math.floor(item.rating)]}
                        color={item.rating === 5 ? 'white' : 'black'}
                        disableArrow={true}
                    />
                ),
                description: <span className='process-description'>{item.description}</span>,
            };
        });
        setProcessData(data);
    }, [processDataResult]);

    const paginationHandler = (currentPage: number, currentPageSize: number) => {
        setPageSize(currentPageSize);
        setCurrentPage(currentPage);
    };
    const handleSort = (label: string, field: string) => {
        setSortCol(field);
        if (sortCol === field) {
            setSort(sort === DESC ? ASC : DESC);
        } else {
            setSort(ASC);
        }
    };

    let timeout: NodeJS.Timeout;
    const onSearch = (e: any) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            setSearchTerm(e.target.value);
        }, 300);
    };
    const getTitle = () => {
        switch (selectedTab?.title) {
            case 'Risks':
                return t('inventoryPage.risks');
            case 'Processes':
                return t('inventoryPage.processes');
            case 'Controls':
                return t('inventoryPage.controls');
            default:
                return '';
        }
    };
    const getTotalCount = () => {
        switch (selectedTab?.title) {
            case 'Risks':
                return riskDataPage?.totalResults || 0;
            case 'Processes':
                return processDataPage?.totalResults || 0;
            case 'Controls':
                return 0;
            default:
                return 0;
        }
    };
    const getColDef = () => {
        switch (selectedTab?.title) {
            case 'Risks':
                return riskColDef;
            case 'Processes':
                return processColDef;
            default:
                return [];
        }
    };
    const getTableData = () => {
        switch (selectedTab?.title) {
            case 'Risks':
                return riskData;
            case 'Processes':
                return processData;
            default:
                return [];
        }
    };
    const getFacets = () => {
        switch (selectedTab?.title) {
            case 'Risks':
                return riskDataFacets;
            case 'Processes':
                return processDataFacets;
            default:
                return {};
        }
    };
    const download = () => {
        switch (selectedTab?.title) {
            case 'Risks':
                downloadRisks({
                    searchTerm,
                    sectorIds: [],
                    businessUnitIds: getIds(inventoryFilters[selectedTab?.title].businessUnit || []) || [],
                    regionIds: getIds(inventoryFilters[selectedTab?.title].regions || []) || [],
                    categoryIds: getIds(inventoryFilters[selectedTab?.title].categories || []) || [],
                    controlIds: [],
                });
                break;
            case 'Processes':
                downloadProcesses({
                    searchTerm,
                    sectorIds: [],
                    businessUnitIds: getIds(inventoryFilters[selectedTab?.title].businessUnit || []) || [],
                    regionIds: getIds(inventoryFilters[selectedTab?.title].regions || []) || [],
                    categoryIds: getIds(inventoryFilters[selectedTab?.title].categories || []) || [],
                    controlIds: [],
                });
                break;
            default:
                break;
        }
    };

    const prevPage = () => {
        navigate('/home');
    };

    return (
        <div className='inventory-container'>
            {isLoading ? <LoaderComponent show={true} fullscreen={true}></LoaderComponent> : ''}
            <div className='section-1'>
                <div className='arrow-position nexus-flex-row' onClick={prevPage}>
                    <NexusIcon src={BackArrow} className='back-btn' />
                    <div className='label-div'>
                        <label className='label-back'>{t('riskDetailPage.Back')}</label>
                    </div>
                </div>

                <div className='nexus-padding-0 separator app-title'></div>

                <div
                    className='nexus-col-xs-2 nexus-col-md-2 nexus-col-lg-7 nexus-col-xl-8 nexus-mr-7 breadcrumb-container'
                    style={{ marginRight: '10px' }}
                >
                    <BreadcrumbComponent separator={'>'} data={breadcrumbData} className='inventory-breadcrumb' />
                </div>
            </div>
            <div className='section-2'>
                <TabsComponent
                    key={selectedTab?.title}
                    tabOption={tabOptions}
                    className='prc-tab'
                    callBack={(data: any) => {
                        setSelectedTab(data);
                    }}
                />
            </div>
            <div className='section-3'>
                <div className='section-3-a'>
                    <div className='tab-title'>{getTitle()}</div>
                    <span className='count'>({getTotalCount()} Entities)</span>
                </div>
                <div className='section-3-b'>
                    <ButtonComponent
                        type={['nexus-btn-primary']}
                        label='Add to Report'
                        extraClass='add-to-report-btn'
                    />
                </div>
            </div>
            <div className='section-4'>
                <div className='section-4-a'>
                    <div className='filter-title-container nexus-flex-row'>
                        <div className='filter-title'>{t('inventoryPage.filter')}</div>
                        <div
                            className='clear-all'
                            onClick={() => {
                                dispatch(resetFilters({}));
                            }}
                        >
                            {'Clear All'}
                        </div>
                    </div>
                    <div>
                        {Object.values(inventoryFilters[selectedTab?.title] || {}).flat().length ? (
                            <div className='selected-filters-display'>
                                {Object.keys(inventoryFilters[selectedTab?.title] || {}).map((key) => {
                                    return (
                                        <div key={key} className='selected-filters'>
                                            {inventoryFilters[selectedTab?.title][key].map((item: any) => {
                                                displayedFitlerCount++;
                                                return (
                                                    displayedFitlerCount <= MAX_VISIBLE_FILTERS && (
                                                        <div key={item.id} className='filter-item'>
                                                            <IconComponent
                                                                className='close-icon'
                                                                src={closeIcon}
                                                                size='sm'
                                                                onClick={() =>
                                                                    dispatch(
                                                                        removeFilter({
                                                                            filter: key,
                                                                            value: item.value,
                                                                            id: item.id,
                                                                            tab: selectedTab?.title,
                                                                        }),
                                                                    )
                                                                }
                                                            />
                                                            <label className='filter-value' title={item?.value}>
                                                                {item?.value}
                                                            </label>
                                                        </div>
                                                    )
                                                );
                                            })}
                                        </div>
                                    );
                                })}
                                {displayedFitlerCount > MAX_VISIBLE_FILTERS && (
                                    <div className='selected-filters'>
                                        <div className='filter-item'>
                                            <label className='filter-items-count'>
                                                +{totalFilters - MAX_VISIBLE_FILTERS}
                                            </label>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div>
                        <Filters
                            filterData={getFacets()}
                            onClick={(data: any) => {
                                if (data.checked !== undefined) {
                                    if (data.checked) {
                                        dispatch(
                                            addFilter({
                                                filter: data.key,
                                                value: data.value,
                                                id: data.id || data.value,
                                                tab: selectedTab?.title,
                                            }),
                                        );
                                    } else {
                                        dispatch(
                                            removeFilter({
                                                filter: data.key,
                                                value: data.value,
                                                id: data.id || data.value,
                                                tab: selectedTab?.title,
                                            }),
                                        );
                                    }
                                }
                            }}
                            onParentClick={(data: any) => {
                                if (data.checked !== undefined) {
                                    if (data.checked) {
                                        dispatch(
                                            addMultipleFilters({
                                                filter: data.key,
                                                values: data.values,
                                                tab: selectedTab?.title,
                                            }),
                                        );
                                    } else {
                                        dispatch(
                                            removeMultipleFilters({
                                                filter: data.key,
                                                values: data.values,
                                                tab: selectedTab?.title,
                                            }),
                                        );
                                    }
                                }
                            }}
                            selectedItems={Object.values(inventoryFilters[selectedTab?.title] || {})
                                .flat()
                                .map((item: any) => item.id || item.value)}
                        />
                    </div>
                </div>
                <CardComponent className='section-4-b'>
                    <div className='section-4b-a'>
                        <div className='section-4b-a-a'></div>
                        <div className='section-4b-a-b'>
                            <ButtonComponent
                                extraClass='download-button'
                                type={[]}
                                label='Download'
                                click={download}
                                icon={<IconComponent src={downloadIcon} size='sm' />}
                            />
                            <InputComponent extraClass={'search-input'} placeholder='Search' onChange={onSearch}>
                                <IconComponent className='search-icon' src={searchIcon} size='sm' />
                            </InputComponent>
                        </div>
                    </div>
                    <div className='section-4b-b'>
                        <div className='custom-table-container'>
                            <CustomTable
                                columnsDef={getColDef()}
                                data={getTableData() || []}
                                className='inventory-table'
                                pagination={true}
                                paginationFunc={paginationHandler}
                                handleSort={handleSort}
                                sortIcon={sort}
                                sortCol={getColDef()
                                    .map((e: any) => e?.field)
                                    .indexOf(sortCol)}
                                currentPageNo={currentPage}
                                pageSizeCustom={pageSize}
                                totalItems={getTotalCount()}
                            />
                        </div>
                    </div>
                </CardComponent>
            </div>
        </div>
    );
}

export default Inventory;
