import React from 'react';

interface Props {
    width?: string;
    height?: string;
    radius?: string;
    color?: string;
    label?: string;
    className?: string;
}
function Chip({ width = '10px', height = '10px', radius = '5px', color = 'grey', label, className }: Props) {
    return (
        <div className={`nexus-flex-row chip-container ${className}`}>
            <div style={{ backgroundColor: color, borderRadius: radius, height, width }}></div>
            {label && <label className='label'>{label}</label>}
        </div>
    );
}

export default Chip;
