import React from 'react';
import Chip from './chip';
import { getUniqueKey } from 'utils/generateButtonIndex';

interface Props {
    data: any[];
    className?: string;
    radius?: string;
    showRange?: boolean;
}
function ColorRange({ data, className = '', radius, showRange = false }: Props) {
    return (
        <div className={`nexus-flex-row color-range-container ${className}`}>
            {showRange && <div className='color-range-number'>0</div>}
            {data.map((chipData, index) => {
                return (
                    <Chip key={getUniqueKey(index, 'color')} {...chipData} width='16px' height='16px' radius={radius} />
                );
            })}
            {showRange && <div className='color-range-number'>{data.length}</div>}
        </div>
    );
}

export default ColorRange;
