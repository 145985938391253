import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
export const processByProcessId = createSelector(
    (state: RootState) => state.processDetailPage,
    (processDetailData) => processDetailData?.processByProcessId || {},
);
export const riskByprocess = createSelector(
    (state: RootState) => state.processDetailPage,
    (processDetailData) => processDetailData.riskByprocessID || [],
);

export const getLoadingState = createSelector(
    (state: RootState) => state.processDetailPage,
    (processDetailData) => processDetailData.isLoading,
);

export const getProcessDetailBreadcrumb = createSelector(
    (state: RootState) => state.risk,
    (risk) => risk.breadcrumbData || [],
);
