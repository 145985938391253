import { useEffect, useState } from 'react';
import CardComponent from 'app/components/nds/card/card';
import AccordionComponent from 'app/components/nds/accordion/accordion';
import './processDetails.scss';
import { useTranslation } from 'react-i18next';
import CardDetails from 'app/components/card/cardDetails';
import BreadcrumbComponent from 'app/components/nds/breadcrumb/breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import {
    getLoadingState,
    getProcessDetailBreadcrumb,
    processByProcessId,
    riskByprocess,
} from './redux/processDetailsSelectors';
import BackArrow from '@nexus/core/dist/assets/icons/action/ic_arrow_left_alt_24px.svg';
import { NexusIcon } from '@nexus/react';
import CustomTable from 'app/components/nds/customTable/customTable';
import ChipArrow from 'app/components/ColorPalette/chipArrow';
import { EFFECTIVENESS_COLOURS, RISK_LEVELS_COLORS } from 'constants/constants';
import { Chip } from 'app/components/ColorPalette';
import { useNavigate, useParams } from 'react-router-dom';
import { getProcessByProcessIdInfo, getRiskByProcessID } from './redux/processDetailsSlice.reducer';
import CountCircle from 'app/components/ColorPalette/CountCircle';
import LoaderComponent from 'app/components/nds/loader/loader';

function ProcessDetails() {
    const { id } = useParams();

    useEffect(() => {
        if (id !== undefined) {
            dispatch(getProcessByProcessIdInfo(id));
            dispatch(getRiskByProcessID({ processID: [id] }));
        }
    }, [id]);

    const { content: processDetailDataResult } = useSelector(riskByprocess);
    const totalCount = useSelector(riskByprocess);
    const processDetail = useSelector(processByProcessId);
    const isLoading = useSelector(getLoadingState);
    const [processDetailData, setprocessDetailData] = useState<any>([]);
    const [processDetailTableData, setprocessDetailTableData] = useState<any>([]);
    const riskName = processDetail?.name;
    const [t] = useTranslation('lang');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const breadcrumbData = useSelector(getProcessDetailBreadcrumb);

    useEffect(() => {
        setprocessDetailData(processDetail);
    }, [processDetail]);
    useEffect(() => {
        const data = processDetailDataResult?.map((item: any) => {
            return {
                riskStatement: <span className='risk-description'> {item.statement}</span>,
                controls: (
                    <span
                        className='effectiveness'
                        style={{
                            backgroundColor: EFFECTIVENESS_COLOURS[item.effectiveness].bgColor,
                            borderColor: EFFECTIVENESS_COLOURS[item.effectiveness].borderColor,
                            color: EFFECTIVENESS_COLOURS[item.effectiveness].textColor,
                        }}
                    >
                        <span className='label'>{t(`inventoryPage.${item.effectiveness}`)}</span>
                    </span>
                ),
                risk_id: (
                    <span onClick={() => navigate(`/risk-detail/${item.id}`)} className='risk-id'>
                        {item.id}
                    </span>
                ),
                inherentRisk: (
                    <>
                        <div className='nexus-flex-row'>
                            <Chip
                                className='risk-level-chip'
                                label={item?.inherentRiskRating}
                                color={RISK_LEVELS_COLORS[Math.floor(item?.inherentRiskRating)]}
                            />
                        </div>
                        <label>
                            <span className='risk-id'>{item.type}</span>
                        </label>
                    </>
                ),
            };
        });
        setprocessDetailTableData(data);
    }, [processDetailDataResult]);

    const processDetailInfo = {
        'ID': processDetailData?.id,
        'DESCRIPTION': processDetailData?.description,
        'PROCESS OWNER(S)': processDetailData?.owners?.map((item: any) => item).join(','),
    };

    const dataSources = {
        'SOURCE': processDetailData?.dataSource?.name,
        'OWNER': processDetailData?.dataSource?.owner,
        'REFRESH DATE': processDetailData?.dataSource?.refreshDate,
    };

    const prevPage = () => {
        navigate(-1);
    };

    const accordionContent = {
        info: '',
        isOpen: true,
        title: (
            <div className='nexus-flex-row'>
                {t(`processDetailPage.risks`)} <CountCircle RisksCount={totalCount?.totalElements} />
            </div>
        ),
    };

    return (
        <div className='process-details'>
            {isLoading ? <LoaderComponent show={true}></LoaderComponent> : ''}
            <div className='breadcrums nexus-row nexus-mb-2'>
                <div className='arrow-position nexus-flex-row' onClick={() => prevPage()}>
                    <NexusIcon src={BackArrow} className='back-btn' />
                    <div className='label-div'>
                        <label className='label-back'>{t('riskDetailPage.Back')}</label>
                    </div>
                </div>

                <div
                    className='nexus-col-xs-2 nexus-col-md-2 nexus-col-lg-7 nexus-col-xl-8 nexus-mr-7'
                    style={{ marginRight: '10px' }}
                >
                    <BreadcrumbComponent separator='>' data={breadcrumbData} key={riskName} />
                </div>
            </div>

            <div className='risk-name nexus-flex-row'>
                <label className='risk-lables'>{processDetailData?.name}</label>
            </div>

            <div className='nexus-flex-row'>
                <div className='nexus-flex-col first-sections'>
                    <label className='first-risk-section'>{t('processDetailPage.rating')}</label>
                    <div className=''>
                        <ChipArrow
                            text={processDetail?.rating}
                            bgColor={RISK_LEVELS_COLORS[Math.round(processDetailData?.rating)]}
                            color={processDetail?.rating === 5 ? 'white' : 'black'}
                            disableArrow={true}
                        />
                    </div>
                </div>
                <div className='nexus-flex-col first-sections'>
                    <label className='first-risk-section'>{t('processDetailPage.lineOfBusiness')}</label>
                    <div className='nexus-flex-row risk-types '>{processDetailData?.businessUnit?.name}</div>
                </div>
                <div className='nexus-flex-col first-sections'>
                    <label className='first-risk-section'>{t('processDetailPage.category')}</label>
                    <div className='nexus-flex-row risk-types '>{processDetailData?.category?.name}</div>
                </div>
                <div className='nexus-flex-col first-sections'>
                    <label className='first-risk-section'>{t('processDetailPage.type')}</label>
                    <div className='nexus-flex-row risk-types '>{processDetailData?.types?.join(', ')}</div>
                </div>
            </div>

            <div className='second-section nexus-flex-row'>
                <div className='second-a-section'>
                    <CardComponent className={'second-a1-section'}>
                        <label className='second-card-title'>{t('riskDetailPage.process')}</label>
                        <CardDetails data={processDetailInfo} />
                    </CardComponent>
                </div>
                <div className='second-b-section'>
                    <CardComponent className={'second-b1-section'}>
                        <label className='second-card-title'>{t('riskDetailPage.dataSource')}</label>
                        <CardDetails data={dataSources} parent={'dataSource'} />
                    </CardComponent>
                </div>
            </div>

            <CardComponent className={'card-a1-section'}>
                <div className='accordion-section'>
                    <AccordionComponent accordionContent={accordionContent} size='md' key={totalCount?.totalElements}>
                        <div>
                            <CustomTable
                                columnsDef={[
                                    {
                                        field: 'risk_id',
                                        id: '0',
                                        isSortable: false,
                                        label: <div>{t('processDetailPage.riskID')}</div>,
                                        minWidth: 150,
                                    },
                                    {
                                        field: 'inherentRisk',
                                        id: '1',
                                        isSortable: false,
                                        label: <div>{t('processDetailPage.inherentRisk')}</div>,
                                        minWidth: 150,
                                    },
                                    {
                                        field: 'controls',
                                        id: '2',
                                        isSortable: false,
                                        label: <div>{t('processDetailPage.controls')}</div>,
                                        minWidth: 150,
                                    },
                                    {
                                        field: 'riskStatement',
                                        id: '3',
                                        isSortable: false,
                                        label: <div>{t('processDetailPage.riskStatement')}</div>,
                                        minWidth: 600,
                                    },
                                ]}
                                data={processDetailTableData || []}
                                className='inventory-table'
                            />
                        </div>
                    </AccordionComponent>
                </div>
            </CardComponent>
        </div>
    );
}

export default ProcessDetails;
