import './card.scss';

import { NexusCard, NexusCardBody } from '@nexus/react';

import React from 'react';

type Props = {
    bgcolor?: string;
    height?: string;
    children?: React.ReactNode;
    className?: string;
};

const CardComponent: React.FC<Props> = ({ children, bgcolor, height, className }) => {
    return (
        <NexusCard data-testid='card' bg-color={bgcolor} height={height} className={`nexus-card ${className}`}>
            <NexusCardBody>{children}</NexusCardBody>
        </NexusCard>
    );
};

export default CardComponent;
