import { NexusBreadcrumb, NexusBreadcrumbItem } from '@nexus/react';
import './breadcrumb.scss';
import { Link } from 'react-router-dom';
import React from 'react';
import { getUniqueKey } from 'utils/generateButtonIndex';

interface Props {
    separator: string;
    data: {
        isEnabled: boolean;
        name: string;
        path: string;
    }[];
    className?: string;
}

const BreadcrumbComponent: React.FC<Props> = ({ separator, data, className = '' }) => {
    return (
        <div className={`nexus-flex-row  ${className}`}>
            <NexusBreadcrumb separator={separator}>
                {data.map((breadcrumbItem: { name: string; path: string; isEnabled: boolean }, idx) => {
                    return (
                        <NexusBreadcrumbItem key={getUniqueKey(idx)} data-testid={breadcrumbItem.name} disabled={false}>
                            {breadcrumbItem.path && breadcrumbItem.isEnabled ? (
                                <Link to={breadcrumbItem.path}>{breadcrumbItem.name}</Link>
                            ) : (
                                breadcrumbItem.name
                            )}
                        </NexusBreadcrumbItem>
                    );
                })}
            </NexusBreadcrumb>
        </div>
    );
};

export default BreadcrumbComponent;
