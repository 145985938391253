import React, { useEffect } from 'react';
import favStarIcon from '@nexus/core/dist/assets/icons/action/ic_grade_24px.svg';
import favStarBorderIcon from '@nexus/core/dist/assets/icons/action/ic_star_half_24px.svg';
import IconComponent from 'app/components/nds/icon/icon';
import { blRisksApiFactory } from 'app/views/risk_details/redux/riskDetailSlice.reducer';
import { NexusTooltip, NexusTooltipContent, NexusTooltipTrigger } from '@nexus/react';

interface Props {
    data: any;
}
function FavComponent({ data }: Props) {
    const [selected, setSelected] = React.useState(data.followed || false);
    useEffect(() => {
        setSelected(data.followed || false);
    }, [data]);

    return (
        <NexusTooltip allowClick={false} placement={'left'}>
            <NexusTooltipTrigger attrId={'follow'}>
                <IconComponent
                    key={data.id + '-' + data.followed ? 'followed' : 'unfollowed'}
                    src={selected ? favStarBorderIcon : favStarIcon}
                    onClick={() => {
                        if (!selected) {
                            blRisksApiFactory.setRiskAsFollow(data.id);
                        } else {
                            blRisksApiFactory.setRiskAsUnfollow(data.id);
                        }
                        setSelected(!selected);
                    }}
                />
            </NexusTooltipTrigger>

            <NexusTooltipContent attrId={'follow-content'}>{selected ? 'Unfollow' : 'Follow'}</NexusTooltipContent>
        </NexusTooltip>
    );
}

export default FavComponent;
