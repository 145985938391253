/* tslint:disable */
/* eslint-disable */
/**
 * DRA DATA
 * Dynamic Risk Assessment API  # Changelog <details> <summary>Click to expand</summary> - 0.4   - Added nexus server [DRA DATA DEV NEXUS]   - Fix risk levels in examples to not exceed value 5:   - Examples enhanced for endpoints:     - GET /risks-types-by-region     - GET /risks-types-by-category - 0.5   - RiskDTO model updated (residualPrevRiskRating, inherentPrevRiskRating)   - GET /snapshot endpoint removed   - Added risk levels to /risks-types-by-sector - 0.6   - Risk search examples updated (IDs to data points) - 0.7   - Added text search capability to search endpoints - 0.8   - Added data fields to ProcessDTO and ControlDTO - 0.9   - Location of pagination and errors changed (moved to api.yml) - 0.10   - Hierarchical facets in search endpoints modified - 0.11   - Added facets risk response - 0.12   - Added facets process response - 0.13   - Added pageable parameters to search/processes   - Response duplicated ids replaced (Sleek Rubber Car process) </details>
 *
 * The version of the OpenAPI document: 0.13
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';

export const BASE_PATH = "https://nexus4risk-dra-dev.sbp.eyclienthub.com/api/dra-data".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: AxiosRequestConfig;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    constructor(public field: string, msg?: string) {
        super(msg);
        this.name = "RequiredError"
    }
}
