import type { Configuration } from 'app/api/dra-rdh-service';

export const rdhApiCall = `${process.env.REACT_APP_BASE_URL}/api/dra-data`;
export const blApiCall = `${process.env.REACT_APP_BASE_URL}/api/dra`;

export const rdhApiConfig: Configuration = {
    basePath: rdhApiCall,
    isJsonMime: function (): boolean {
        return true;
    },
};

export const blApiConfig: Configuration = {
    basePath: blApiCall,
    isJsonMime: function (): boolean {
        return true;
    },
};
