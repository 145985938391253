/* tslint:disable */
/* eslint-disable */
/**
 * DRA
 * Dynamic Risk Assessment API  # Changelog <details> <summary>Click to expand</summary> - 0.1   - create empty openApi specification - 0.2   - Added DRA endpoints with examples:     - POST /reports     - GET /reports?userId={userId}     - GET /reports/{reportId} - 0.3   - Location of pagination and errors changed (moved to api.yml)   - eliminated compile error - 0.4   - Added download risks csv file endpoint - 0.5   - Added download processes csv file endpoint    - 0.6   - Added sort parameter to download processes - 0.7   - Added endpoints:     - GET /risks     - PUT /risks/{riskId}/follow     - PUT /risks/{riskId}/unfollow     - GET /search/risks - 0.8   - Added GET /risks/{riskId} endpoint - 0.9   - Added risk score category label for both Inherent risk and residual risk to the risk endpoint - 0.10   - Changed followed facets from boolean to enum </details>
 *
 * The version of the OpenAPI document: 0.10
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';

export const BASE_PATH = "http://localhost:8080/api".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: AxiosRequestConfig;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    constructor(public field: string, msg?: string) {
        super(msg);
        this.name = "RequiredError"
    }
}
