import React, { useState } from 'react';
import { NexusTabBar } from '@nexus/react';

interface Props {
    tabOption: {
        value?: any;
        title: any;
        isActive?: boolean;
    }[];
    className?: string;
    key?: string | number;
    callBack?: (data: any) => void;
}
const TabsComponent: React.FC<Props> = (props: Props) => {
    const [newtabOption, setnewtabOption] = useState(props.tabOption);
    const ChangeActive = (index: number) => {
        const selectedTab = [...props.tabOption];

        selectedTab.forEach((option) => {
            option.isActive = false;
        });
        selectedTab[index].isActive = true;
        props?.callBack && props?.callBack(selectedTab[index]);
        setnewtabOption(selectedTab);
    };

    const getUniqueKey = (idx: number) => {
        return `nexus-tabbar-${idx}`;
    };

    const getUniqueKeySpan = (idx: number) => {
        return `nexus-tabbar-span-${idx}`;
    };
    return (
        <div className={props.className || 'Tab'} key={props.key}>
            <NexusTabBar data-testid='nexus-tabbar'>
                <div>
                    {newtabOption.map((option: any, index: number) => (
                        <span key={getUniqueKeySpan(index)}>
                            <button
                                className={`tab-title nexus-link ${
                                    option.isActive ? 'nexus-active' : 'nexus-inactive'
                                }`}
                                data-testid={option.title}
                                onClick={() => ChangeActive(index)}
                            >
                                {option.title}
                            </button>
                        </span>
                    ))}
                </div>
            </NexusTabBar>
            <div>
                {newtabOption.map(
                    (option: any, index: number) =>
                        option.isActive && (
                            <div key={getUniqueKey(index)} className='tab-value'>
                                {option.value}
                            </div>
                        ),
                )}
            </div>
        </div>
    );
};

export default TabsComponent;
