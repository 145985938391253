import './CountCircle.scss';

interface RiskCountCircleProps {
    RisksCount: number;
}

const CountCircle: React.FC<RiskCountCircleProps> = ({ RisksCount }) => {
    return (
        <div className='circle-container'>
            <div className='circle'>{RisksCount}</div>
        </div>
    );
};

export default CountCircle;
