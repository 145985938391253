import './header.scss';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { NexusHeader, NexusHeaderLogo, NexusHeaderMenu, NexusIcon, NexusMenuItem } from '@nexus/react';

import DropdownComponent from './dropdown/dropdown';
import Logo from '@nexus/core/dist/assets/images/nexus-logo.svg';
import React, { useEffect, useState } from 'react';
import { retrieveUserName } from '../../../../auth';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AvatarComponent from './avatar/avatar';
import { getAvatarLabel } from 'utils/actionUtils';
import { HOME_URL, INVENTORY_URL } from 'constants/constants';

interface MenuItem {
    icon?: string;
    link: string;
    title: string;
    onClick?: Function;
}
interface Props {
    home: string;
    menuItems: MenuItem[];
}
const HeaderComponent: React.FC<Props> = ({ home }) => {
    const [t] = useTranslation('lang');
    const navigate = useNavigate();
    const location = useLocation();
    const currentpath = location.pathname;
    const homeUrl = HOME_URL;
    const fullName = useSelector(retrieveUserName);
    const [toggleDropDown, setToggleDropDown] = useState<boolean>(false);

    const headerNavigate = () => {
        navigate(homeUrl);
    };
    const InventoryNavigate = () => {
        navigate(INVENTORY_URL);
    };

    const closeMenu = () => {
        const closeBtn = document.getElementsByTagName('nexus-menu')[0]?.getElementsByTagName('nexus-icon');
        return closeBtn && closeBtn.length > 0 ? closeBtn[0].click() : null;
    };
    const dropDownMenu = () => {
        setToggleDropDown(!toggleDropDown);
    };
    const handleClickOutside = (event: any) => {
        if (event?.target?.lastChild?.length !== 2 && ![...(event?.target?.classList || [])].includes('initials')) {
            setToggleDropDown(false);
        }
    };

    const dropdownOptions = [
        {
            clickButton: closeMenu,
            extraStyle: 'link-color',
            label: t('loginProfile.profile'),
            link: '/profile',
        },
        {
            extraStyle: 'link-color',
            label: t('loginProfile.logout'),
            link: '/logout',
        },
    ];

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
    }, []);
    return (
        <NexusHeader class='nexus-header-sticky nexus-header hydrated header' data-testid='nexus-header'>
            <NexusHeaderLogo className='display-inline padding nexus-py-2'>
                <Link to={home}>
                    <NexusIcon src={Logo} />
                    <span className='nexus-visually-hidden'></span>
                </Link>
                <div className='nexus-padding-2 separator app-title'>Dynamic Risk Assessment</div>
            </NexusHeaderLogo>
            <NexusHeaderMenu data-testid='nexus-header-menu'>
                {/* {menuItems.map((menuItem, idx) =>
                    menuItem.icon ? (
                        <NexusMenuItem key={getUniqueKey(idx)} data-testid={menuItem.title} onClick={closeMenu}>
                            <Link to={menuItem.link}>
                                <NexusIcon src={menuItem.icon}>{menuItem.title}</NexusIcon>
                            </Link>
                        </NexusMenuItem>
                    ) : (
                        <NexusMenuItem
                            key={getUniqueKey(idx)}
                            data-testid={menuItem.title}
                            onClick={() => {
                                closeMenu();
                                menuItem.onClick && menuItem.onClick();
                            }}
                        >
                            <Link to={menuItem.link}>
                                <div className={`${menuItem.link}` === path.pathname ? 'menu-item-highlighter' : ''}>
                                    {menuItem.title}
                                </div>
                            </Link>
                        </NexusMenuItem>
                    ),
                )} */}

                <NexusMenuItem>
                    <div
                        className={`header-item ${currentpath === homeUrl ? 'menu-item-highlighter' : ''}`}
                        onClick={headerNavigate}
                    >
                        Dashboard
                    </div>
                </NexusMenuItem>

                <NexusMenuItem>
                    <div
                        className={`header-item ${currentpath === INVENTORY_URL ? 'menu-item-highlighter' : ''}`}
                        onClick={InventoryNavigate}
                    >
                        Inventory
                    </div>
                </NexusMenuItem>

                {/* <NexusMenuItem>
                    <NexusIcon src={Alert} />
                </NexusMenuItem> */}
                <NexusMenuItem>
                    <div className='nexus-mt-1 user-profile-flyout' onClick={() => dropDownMenu()}>
                        <DropdownComponent
                            toggleDropDown={toggleDropDown}
                            dropdownOptions={dropdownOptions}
                            triggererContent={<AvatarComponent name={getAvatarLabel(fullName)} showName={false} />}
                            placement='left'
                            dropdownType='basic'
                            height={280}
                            width={245}
                        />
                    </div>
                </NexusMenuItem>
            </NexusHeaderMenu>
        </NexusHeader>
    );
};

export default HeaderComponent;
