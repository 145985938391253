/* eslint-disable sort-keys */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import './map.scss';
import Map, { Layer, Source } from 'react-map-gl/maplibre';
import { dataLayer } from 'app/assets/maps/layerStyle';
import geoData from 'app/assets/maps/geoJson.json';
import mapStyles from 'app/assets/maps/styles.json';
import LoaderComponent from '../nds/loader/loader';
interface Props {
    data: { code: string; riskLevel: number; color: string }[];
    className?: string;
}
function MapComponent({ data, className }: Props) {
    const containerRef = useRef(null);
    const [styles, setStyles] = useState<any>({});
    const [hoverInfo, setHoverInfo] = useState<any>(null);
    const [zoom, setZoom] = useState({ minZoom: 1, maxZoom: 3 });
    const calculateMinZoom = (width: number) => {
        if (width <= 1300) return { minZoom: 1, maxZoom: 2.5 };
        if (width < 2000 && width > 1300) return { minZoom: 1.5, maxZoom: 4 };
        if (width >= 2000 && width < 3000) return { minZoom: 2, maxZoom: 5 };
        return { minZoom: 2.5, maxZoom: 6 };
    };

    useEffect(() => {
        window?.innerWidth && setZoom(calculateMinZoom(window?.innerWidth));
    }, []);
    useEffect(() => {
        function handleResize() {
            const newWidth = window.innerWidth;
            setZoom(calculateMinZoom(newWidth));
        }

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        const updatedStyles = JSON.parse(JSON.stringify(mapStyles));
        data.forEach((country: any) => {
            updatedStyles.layers[2].paint['fill-color'].push([country.code]);
            updatedStyles.layers[2].paint['fill-color'].push(country.color);
        });
        updatedStyles.layers[2].paint['fill-color'].push('#826a6a59');
        setStyles(updatedStyles);
    }, [data]);
    const onHover = useCallback((event: any) => {
        const {
            features,
            point: { x, y },
        } = event;
        const hoveredFeature = features && features[0] && features[0].properties;
        const countryDetails: any = data.find((country: any) => country.code === hoveredFeature?.adm0_a3);
        // prettier-ignore
        setHoverInfo(
            hoveredFeature && {
                feature: { country: hoveredFeature.name, residualRiskLevel: countryDetails?.residualRiskLevel || '-' , inherentRiskLevel: countryDetails?.inherentRiskLevel || '-'},
                x,
                y,
            },
        );
    }, []);
    const isMaploading = () => {
        if (
            (styles?.layers && styles.layers[2].paint['fill-color'].length < 4) ||
            Object.keys(styles).length === 0 ||
            (Object.keys(styles).length > 0 && styles.version === undefined) ||
            (Object.keys(styles).length > 0 && styles.sources === undefined) ||
            (Object.keys(styles).length > 0 && styles.layers === undefined)
        ) {
            return true;
        }
        return false;
    };
    return (
        <div className={className}>
            {isMaploading() ? (
                <LoaderComponent show={true} />
            ) : (
                <Map
                    ref={containerRef}
                    initialViewState={{
                        latitude: 40,
                        longitude: -100,
                    }}
                    mapStyle={styles}
                    minZoom={zoom.minZoom}
                    maxZoom={zoom.maxZoom}
                    interactiveLayerIds={['data']}
                    onMouseMove={onHover}
                    onMouseOut={() => setHoverInfo(null)}
                >
                    <Source type='geojson' data={geoData}>
                        <Layer {...dataLayer} type='fill' />
                    </Source>
                    {hoverInfo && (
                        <div className='tooltip' style={{ left: hoverInfo.x, top: hoverInfo.y }}>
                            <div className='arrow'></div>
                            <div>{hoverInfo.feature.country}</div>
                            <div>Residual Risk level: {hoverInfo.feature.residualRiskLevel}</div>
                            <div>Inherent Risk level: {hoverInfo.feature.inherentRiskLevel}</div>
                        </div>
                    )}
                </Map>
            )}
        </div>
    );
}

export default MapComponent;
