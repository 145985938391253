import { NexusCheckbox } from '@nexus/react';
import downIcon from '@nexus/core/dist/assets/icons/action/ic_expand_more_24px.svg';
import upIcon from '@nexus/core/dist/assets/icons/action/ic_expand_less_24px.svg';
import search from '@nexus/core/dist/assets/icons/action/ic_search_24px.svg';
import { useState } from 'react';
import './index.scss';
import IconComponent from 'app/components/nds/icon/icon';
import InputComponent from 'app/components/nds/input/input';
import { useTranslation } from 'react-i18next';

const Filter = ({ data, parent, type, onClick, selectedItems = [], onParentClick }: any) => {
    const totalItems = data.length;

    let filterViewType = '';
    if (totalItems <= 10 && totalItems > 5) {
        filterViewType = 'SHOW_MORE';
    } else if (totalItems > 10) {
        filterViewType = 'SEARCH';
    }
    const [showMore, setShowMore] = useState(false);
    const [filterData, setFilterData] = useState(data.slice(0, 5));
    const onSerach = (e: any) => {
        const searchValue = e.target.value;
        const filteredData = data.filter((item: any) => item.value.toLowerCase().includes(searchValue.toLowerCase()));
        setFilterData(filteredData);
    };

    return (
        <>
            <div className={parent ? 'filter-parent' : 'filter'}>
                {filterViewType === 'SEARCH' && (
                    <InputComponent extraClass='search-input' type='text' placeholder='Search' onChange={onSerach}>
                        <IconComponent src={search} />
                    </InputComponent>
                )}
                <div className='filter-items'>
                    {filterData.map((item: any) => {
                        if (item.children) {
                            return (
                                <FilterContainer
                                    key={item.id}
                                    data={item.children}
                                    filter={item.value}
                                    type={item.childrenType}
                                    onClick={onClick}
                                    onParentClick={onParentClick}
                                    selectedItems={selectedItems}
                                />
                            );
                        } else {
                            return (
                                <div className='filter-item'>
                                    <NexusCheckbox
                                        className='checkbox'
                                        onClick={(e: any) => {
                                            onClick({
                                                checked: e.target.checked,
                                                id: item?.id,
                                                key: type,
                                                value: item?.value,
                                            });
                                        }}
                                        checked={selectedItems.includes(item?.id)}
                                        key={item?.id}
                                    ></NexusCheckbox>
                                    <label className='filter-item-value' title={item?.value}>
                                        {item?.value}
                                    </label>
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
            {(filterViewType === 'SHOW_MORE' || filterViewType === 'SEARCH') && (
                <div
                    className='show-more'
                    onClick={() => {
                        setFilterData(data.slice(0, showMore ? 5 : data.length));
                        setShowMore(!showMore);
                    }}
                >
                    {showMore ? 'Show Less' : 'Show More'}
                </div>
            )}
        </>
    );
};

function FilterContainer({ data, filter, parent = false, type, onClick, onParentClick, selectedItems }: any) {
    const [t] = useTranslation('lang');
    const [expand, setExpand] = useState(false);
    const allSelected = data.every((item: any) => selectedItems.includes(item.id));
    return (
        <div key={filter} className={parent ? 'filter-container-parent' : 'filter-container'}>
            {parent ? (
                <div className='parent-filter-title-container'>
                    <IconComponent
                        className='filter-expand-icon'
                        src={expand ? upIcon : downIcon}
                        onClick={() => setExpand(!expand)}
                    />
                    <div className='parent-filter-title'>{t('inventoryPage.filters.' + filter)}</div>
                </div>
            ) : (
                <div className='filter-container-checkbox'>
                    <IconComponent
                        className='filter-expand-icon'
                        src={expand ? upIcon : downIcon}
                        onClick={() => setExpand(!expand)}
                    />
                    <NexusCheckbox
                        className='checkbox'
                        onClick={(e: any) => {
                            onParentClick({
                                checked: e.target.checked,
                                id: filter,
                                key: type,
                                values: data.map((item: any) => {
                                    return { id: item.id, value: item.value };
                                }),
                            });
                        }}
                        key={filter}
                        checked={allSelected}
                    ></NexusCheckbox>
                    <div>{filter}</div>
                </div>
            )}
            {expand && (
                <Filter
                    data={data}
                    parent={parent}
                    type={type}
                    onClick={onClick}
                    selectedItems={selectedItems}
                    onParentClick={onParentClick}
                />
            )}
        </div>
    );
}

function Filters({ filterData, onClick, onParentClick, selectedItems }: any) {
    return (
        <div>
            {filterData &&
                Object.keys(filterData).map((filter) => {
                    return (
                        <FilterContainer
                            key={filter}
                            data={filterData[filter]}
                            filter={filter}
                            parent={true}
                            type={filter}
                            onClick={onClick}
                            onParentClick={onParentClick}
                            selectedItems={selectedItems}
                        />
                    );
                })}
        </div>
    );
}

export default Filters;
