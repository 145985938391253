import { removeMessage } from 'utils/actionUtils';
import { createAppSlice } from 'store/sliceHelper';
import {
    AggregationsForControlsApiFactory,
    AggregationsForRisksApiFactory,
    CLevelsCell,
    PRLevelsCell,
    ProcessesApiFactory,
    ContextApiFactory,
    CategoryPageDTO,
    AggregateRiskByCategoryPageDTO,
    AggregateRiskByRegionPageDTO,
    AggregateRiskByBuPageDTO,
    AggregateRiskByRegionDTO,
    RisksApiFactory,
    RiskPageDTO,
    ProcessPageDTO,
    ControlsApiFactory,
} from 'app/api/dra-rdh-service';
import { ReducerCreators } from '@reduxjs/toolkit';
import { rdhApiConfig, blApiConfig } from 'constants/apiConstants';

const aggregationsForRisksApiFactory = AggregationsForRisksApiFactory(rdhApiConfig);
const aggregationsForControlsApiFactory = AggregationsForControlsApiFactory(rdhApiConfig);
const contextApiFactory = ContextApiFactory(rdhApiConfig);
const processesApiFactory = ProcessesApiFactory(rdhApiConfig);
const risksApiFactory = RisksApiFactory(blApiConfig);
const controlsApiFactory = ControlsApiFactory(rdhApiConfig);

type GetRiskByIdParams = {
    businessUnitIds: Array<string>;
    regionIds: Array<string>;
};
export const INITIAL_STATE_HOME: any = {
    allCategoriesInfo: {},
    allRiskSectorsinfo: {},
    controlEffectivenessCount: {},
    getriskbyID: [],
    messages: [],
    processesData: {},
    regionByRegionID: {},
    riskCategoryInfo: {},
    riskRegionInfo: {},
    riskTypesByLevel: [],
};
type homeState = Readonly<typeof INITIAL_STATE_HOME>;
export const homeSliceReducer = createAppSlice({
    initialState: INITIAL_STATE_HOME as homeState,
    name: 'homeData',
    reducers: (create: ReducerCreators<any>) => {
        return {
            getAllCatagoryInfo: create.asyncThunk(
                async (_: void, { rejectWithValue }) => {
                    try {
                        const response: { data: CategoryPageDTO } = await contextApiFactory.getAllCategories();
                        return response;
                    } catch (error) {
                        return rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.allCategoriesInfo = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'getAllCatagoryInfo');
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),
            getAllRiskSectorsInfo: create.asyncThunk(
                async (payload: any, { rejectWithValue }) => {
                    try {
                        const response: { data: AggregateRiskByBuPageDTO } =
                            await aggregationsForRisksApiFactory.getAllRiskSectors(payload?.categoryId);
                        return response;
                    } catch (error) {
                        rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.allRiskSectorsinfo = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'getAllRiskSectorsInfo');
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),
            getControlEffectivenessCount: create.asyncThunk(
                async (_: void, { rejectWithValue }) => {
                    try {
                        const response: { data: CLevelsCell } =
                            await aggregationsForControlsApiFactory.getControlByEffectiveness();
                        return response;
                    } catch (error) {
                        rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.controlEffectivenessCount = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'getControlEffectivenessCount');
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),
            getProcessesData: create.asyncThunk(
                async (_: void, { rejectWithValue }) => {
                    try {
                        const response: { data: any } = await processesApiFactory.getAllProcesses();
                        return response;
                    } catch (error) {
                        rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.processesData = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'getProcessesData');
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),

            getRiskById: create.asyncThunk(
                async ({ businessUnitIds, regionIds }: GetRiskByIdParams, { rejectWithValue }) => {
                    try {
                        const response: { data: RiskPageDTO } = await risksApiFactory.getRisks(
                            [''],
                            businessUnitIds,
                            regionIds,
                            [''],
                            [''],
                        );
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.getriskbyID = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'getRiskById');
                    },
                    rejected: (state) => {
                        console.log('rejected the api');
                        state.isLoading = false;
                    },
                },
            ),

            getAllProcessesByFilter: create.asyncThunk(
                async ({ businessUnitIds, regionIds }: GetRiskByIdParams, { rejectWithValue }) => {
                    try {
                        const response: { data: ProcessPageDTO } = await processesApiFactory.getAllProcesses(
                            [''],
                            businessUnitIds,
                            regionIds,
                            [''],
                            [''],
                        );
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.allFilteredProcess = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'getProcessById');
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),

            getAllControlsByFilter: create.asyncThunk(
                async ({ businessUnitIds, regionIds }: GetRiskByIdParams, { rejectWithValue }) => {
                    try {
                        const response: { data: ProcessPageDTO } = await controlsApiFactory.getAllControls(
                            [''],
                            businessUnitIds,
                            regionIds,
                            [''],
                            [''],
                        );
                        return response;
                    } catch (error: any) {
                        return rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.allFilteredControls = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'getControlsById');
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),

            getControlsData: create.asyncThunk(
                async (_: void, { rejectWithValue }) => {
                    try {
                        const response: { data: any } = await controlsApiFactory.getAllControls();
                        return response;
                    } catch (error) {
                        rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.controlsData = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'getControlsData');
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),

            getRiskByRegionId: create.asyncThunk(
                async (payload: { regionId: string }, { rejectWithValue }) => {
                    try {
                        const response: { data: AggregateRiskByRegionDTO } =
                            await aggregationsForRisksApiFactory.getRiskRegionByRegionId(payload?.regionId);
                        return response;
                    } catch (error) {
                        rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.regionByRegionID = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'getRiskByRegionId');
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),

            getRiskCatagoryInfo: create.asyncThunk(
                async (payload: any, { rejectWithValue }) => {
                    try {
                        const response: { data: AggregateRiskByCategoryPageDTO } =
                            await aggregationsForRisksApiFactory.getAllRiskCategory(payload?.categoryId);
                        return response;
                    } catch (error) {
                        rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.riskCategoryInfo = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'getRiskCatagoryInfo');
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),

            getRiskTypeByRegionInfo: create.asyncThunk(
                async (payload: any, { rejectWithValue }) => {
                    try {
                        const response: { data: AggregateRiskByRegionPageDTO } =
                            await aggregationsForRisksApiFactory.getAllRiskRegions(payload?.categoryId);
                        return response;
                    } catch (error) {
                        rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.riskRegionInfo = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'getRiskTypeByRegionInfo');
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),

            getRiskTypesByLevel: create.asyncThunk(
                async (payload: any, { rejectWithValue }) => {
                    try {
                        const response: { data: Array<PRLevelsCell> } =
                            await aggregationsForRisksApiFactory.getCountRisksInRange(payload?.categoryId);
                        return response;
                    } catch (error) {
                        rejectWithValue(error);
                    }
                },
                {
                    fulfilled: (state, action) => {
                        state.isLoading = false;
                        state.riskTypesByLevel = action.payload?.data;
                    },
                    pending: (state) => {
                        state.isLoading = true;
                        state.messages = removeMessage(state, 'getRiskTypesByLevel');
                    },
                    rejected: (state) => {
                        state.isLoading = false;
                    },
                },
            ),
        };
    },
});
export const {
    getRiskCatagoryInfo,
    getRiskTypeByRegionInfo,
    getAllCatagoryInfo,
    getAllRiskSectorsInfo,
    getControlEffectivenessCount,
    getProcessesData,
    getRiskTypesByLevel,
    getRiskByRegionId,
    getRiskById,
    getAllProcessesByFilter,
    getAllControlsByFilter,
    getControlsData,
} = homeSliceReducer.actions;
export default homeSliceReducer.reducer;
