import './authContainer.scss';

import { AuthProvider, refreshAuthToken } from '../../../auth';
import Header from '../../components/nds/header/header';
import LoaderComponent from '../../components/nds/loader/loader';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ToastComponent from 'app/components/nds/toast/toast';
import store from '../../../store';
import { useAppSelector } from 'store';
import ModalComponent from 'app/components/nds/modal/modal';
import ButtonComponent from 'app/components/nds/button/button';
import FooterComponent from 'app/components/nds/footer/footer';
import { HOME_URL, INVENTORY_URL } from 'constants/constants';
import { updateBreadcrumb } from 'app/views/inventory/redux/inventorySlice.reducer';
import { useDispatch } from 'react-redux';

type Props = {
    children?: React.ReactNode;
    roles?: string[];
};

const AuthContainer = ({ children }: Props) => {
    const IDLE_MINUTES = 15 * 60;
    const FIVE_MINUTES = 5 * 60;
    const TOKEN_EXPIRATION_WINDOW_MILLIS = 10 * 60 * 1000;
    const [isLoading, setIsLoading] = useState(false);
    const [messages, setMessages] = useState<any[]>([]);
    const [time, setTime] = useState(IDLE_MINUTES); // Time in minutes
    const [show, setShow] = useState(false);
    const accessToken = useAppSelector((state) => state.auth.accessToken);
    const homeUrl = HOME_URL;
    const tokenExpirationTime = useAppSelector((state) => state.auth.tokenExpiration);
    const dispatch = useDispatch();
    //----------------- refresh the token if it is about to expire -----------------//
    useEffect(() => {
        // using settimeout to refresh the token 10 minutes before it expires
        const timeout = setTimeout(() => {
            if (accessToken) {
                dispatch(refreshAuthToken());
            }
        }, tokenExpirationTime - Date.now() - TOKEN_EXPIRATION_WINDOW_MILLIS);
        return () => clearTimeout(timeout);
    }, [tokenExpirationTime]);
    //------------------------------------------------------------------------------------------//

    //----------------- Track user activity based on mouse and keyboard events -----------------//
    const triggerTimerModal = (status: boolean) => {
        setShow(status);
        sessionStorage.setItem('show', status + '');
    };
    useEffect(() => {
        if (time === 0) {
            if (show) {
                triggerTimerModal(false);
                window.location.href = window.location.origin + '/logout';
            } else {
                triggerTimerModal(true);
                setTime(FIVE_MINUTES);
            }
            // Your logic to handle session timeout
        }
    }, [time]);
    useEffect(() => {
        const handleActivity = () => {
            // Your logic to handle user activity
            const isModalOpen = sessionStorage.getItem('show');
            if (isModalOpen !== 'true') {
                setTime(IDLE_MINUTES);
            }
        };

        const timer = () => {
            // Your logic to reset the timer
            setTime((prevTime) => {
                return prevTime - 1;
            });
        };

        // Attach event listeners
        document.addEventListener('mousemove', handleActivity);
        document.addEventListener('keydown', handleActivity);

        // Reset timer on user activity
        const activityTimer = setInterval(timer, 1000);

        // Clean up event listeners and timer on component unmount
        return () => {
            document.removeEventListener('mousemove', handleActivity);
            document.removeEventListener('keydown', handleActivity);
            clearInterval(activityTimer);
        };
    }, []);
    //------------------------------------------------------------------------------------------//

    //---------------------------Loader and Toast messages-----------------------------------------------------------------------//
    useEffect(() => {
        setIsLoading(false);
    }, []);
    useEffect(() => {
        setMessages([]);
    }, []);

    const handleCloseToast = () => () => {
        // dispatch(removeToast(message.id));
    };
    //--------------------------------------------------------------------------------------------------------------------------//
    //-----------------  Redirect to login if user is not authenticated and then redirect back to initial url-----------------//
    const currPath = window.location.pathname;
    const redirectUrl = localStorage.getItem('redirectUrl') || '';

    if (!accessToken) {
        if (!redirectUrl) localStorage.setItem('redirectUrl', currPath);
        window.location.assign(window.location.origin + '/');
    }

    if (accessToken && redirectUrl && redirectUrl !== currPath) {
        window.location.assign(window.location.origin + redirectUrl);
        localStorage.removeItem('redirectUrl');
    }

    //--------------------------------------------------------------------------------------------------------------------------//
    //-----------------  Redirect to unauth page if user is not authenticated -------------------------------------------------//
    // if (roles && userRoles && !roles.some((item) => userRoles.includes(item))) {
    //     return <Unauthorized />;
    // }
    //--------------------------------------------------------------------------------------------------------------------------//
    const formatTime = (time: number): string => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };
    return (
        <AuthProvider store={store}>
            <Header
                home={homeUrl}
                menuItems={[
                    {
                        link: INVENTORY_URL,
                        onClick: () => {
                            dispatch(
                                updateBreadcrumb([
                                    { isEnabled: true, name: 'Dashboard', path: homeUrl },
                                    { isEnabled: false, name: 'Inventory', path: INVENTORY_URL },
                                ]),
                            );
                        },
                        title: 'Inventory',
                    },
                    { title: 'Report', link: '/report' },
                ]}
            ></Header>
            <div className='app-body'>
                <div className='toast-container'>
                    {messages.map((message: any) => {
                        return (
                            <div className='toast'>
                                <ToastComponent
                                    show={!!message?.type}
                                    variant={message?.type}
                                    message={message?.message}
                                    key={message?.message}
                                    autoClose={message?.autoClose}
                                    closeToast={handleCloseToast()}
                                />
                            </div>
                        );
                    })}
                </div>
                <LoaderComponent show={isLoading} fullscreen={true} />
                <ModalComponent
                    show={show}
                    closeModal={() => {
                        triggerTimerModal(false);
                        setTime(IDLE_MINUTES);
                    }}
                    header={<div className='nexus-pt-2 inactive-header'>Inactive Warning</div>}
                    size='xl'
                    footerContent={
                        <>
                            <ButtonComponent
                                label={'Continue'}
                                extraClass='nexus-ml-auto'
                                type={['nexus-btn nexus-btn-medium']}
                                click={() => {
                                    triggerTimerModal(false);
                                    setTime(IDLE_MINUTES);
                                }}
                            />
                        </>
                    }
                >
                    <div>
                        <div className='timer-container'>
                            <p>Session will end in</p> <p className='timer'>{formatTime(time)}</p>
                        </div>
                        <div> If you want to continue the session, please click on the continue</div>
                    </div>
                </ModalComponent>
                <div className='idle-time'>idel time{formatTime(time)}</div>
                <div className='background-display'>{children}</div>
            </div>
            <FooterComponent />
        </AuthProvider>
    );
};

AuthContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

export default AuthContainer;
