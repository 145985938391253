import React, { ReactNode } from 'react';
import './cardDetails.scss';
import { Chip } from '../ColorPalette';
import { CONNECTION_STATUS_COLORS } from 'constants/constants';
interface Idata {
    [key: string]: string | number | boolean | any;
}
interface cardDetailsProps {
    data?: Idata;
    children?: ReactNode;
    parent?: string;
}
const CardDetails = ({ data, children, parent }: cardDetailsProps) => {
    const getValue = (key: string, value: string) => {
        switch (parent + '-' + key) {
            case 'datasource-CONNECTION STATUS':
                return <Chip className='risk-level-chip' label={value} color={CONNECTION_STATUS_COLORS[value]} />;
            default:
                return value;
        }
    };
    return (
        <div className='card-section'>
            {data && (
                <table>
                    <tbody>
                        {Object.entries(data).map(([key, value], index) => (
                            <tr key={index} className='nexus-table-row'>
                                <td className='options-label-first'>{key}</td>
                                <td className='options-label-second'>{getValue(key, value)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            {children}
        </div>
    );
};

export default CardDetails;
