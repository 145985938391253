import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store';
export const allRiskCategories = createSelector(
    (state: RootState) => state.home,
    (homeData) => homeData?.riskCategoryInfo?.content || [],
);
export const allRiskRegion = createSelector(
    (state: RootState) => state.home,
    (homeData) => homeData?.riskRegionInfo?.content || [],
);
export const allRiskLevels = createSelector(
    (state: RootState) => state.home,
    (homeData) => homeData?.riskTypesByLevel || [],
);

export const controlEffectivenessCountSelector = createSelector(
    (state: RootState) => state.home,
    (homeData) => homeData?.controlEffectivenessCount || {},
);

export const allcatergories = createSelector(
    (state: RootState) => state.home,
    (homeData) => homeData.allCategoriesInfo?.content || [],
);
export const getAllProcessesSelector = createSelector(
    (state: RootState) => state.home,
    (homeData) => homeData.processesData || [],
);
export const getAllControlsSelector = createSelector(
    (state: RootState) => state.home,
    (homeData) => homeData.controlsData || [],
);
export const getAllRiskSector = createSelector(
    (state: RootState) => state.home,
    (homeData) => homeData.allRiskSectorsinfo?.content || [],
);
export const getRigionByID = createSelector(
    (state: RootState) => state.home,
    (homeData) => homeData.regionByRegionID || {},
);
export const getRisksId = createSelector(
    (state: RootState) => state.home,
    (homeData) => homeData.getriskbyID || [],
);
export const getprocessId = createSelector(
    (state: RootState) => state.home,
    (homeData) => homeData.getriskbyID || [],
);
export const getAllFilteredProcessesSelector = createSelector(
    (state: RootState) => state.home,
    (homeData) => homeData.allFilteredProcess || [],
);
export const getAllFilteredControlSelector = createSelector(
    (state: RootState) => state.home,
    (homeData) => homeData.allFilteredControls || [],
);
