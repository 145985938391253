export const HOME_URL = '/home';
export const INVENTORY_URL = '/inventory';
export const ASC = 'ASC';
export const DESC = 'DESC';
export enum DateFormat {
    format = 'yyyy-MM-dd',
    monthPlaceholder = 'MM',
    dayPlaceholder = 'DD',
    yearPlaceholder = 'YYYY',
}
export const ErrorBoundaries = 'Error Boundary';

export const error = 'error';
export const valid = 'valid';
export const invalid = 'invalid';

export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const RISK_LEVELS_COLORS: any = {
    1: '#7EDC74',
    2: '#C4CF58',
    3: '#F5C543',
    4: '#EB8B2F',
    5: '#DB3811',
};
export const CONNECTION_STATUS_COLORS: any = {
    Bad: '#FF0000',
    Good: '#008000',
};
export const EFFECTIVE_LEVELS_COLORS: any = {
    EFFECTIVE: '#509BC8',
    INEFFECTIVE: '#A251A2',
};
export const EFFECTIVE_LEVELS_BCOLORS: any = {
    EFFECTIVE: '#509BC8',
    INEFFECTIVE: '#A251A2',
};
export const CARD_RISK_LEVEL_COLORS: any = {
    1: '#CEF2CA',
    2: '#EDF2C0',
    3: '#FBE9B6',
    4: '#F9D8B8',
    5: '#FAC9BD',
};
export const defaultColorScheme = {
    bgColor: '#FFFFFF',
    borderColor: '#509BC8',
    textColor: '#377398',
};
export const EFFECTIVENESS_COLOURS: any = {
    EFFECTIVE: {
        bgColor: '#FFFFFF',
        borderColor: '#509BC8',
        textColor: '#377398',
    },
    INEFFECTIVE: {
        bgColor: '#F9E5FF',
        borderColor: '#A251A2',
        textColor: '#440544',
    },
    NOT_RATED: {
        bgColor: '#EAEBEB',
        borderColor: '#7A7F85',
        textColor: '#484A4C',
    },
    PARTIAL: {
        bgColor: '#F9E5FF',
        borderColor: '#A251A2',
        textColor: '#440544',
    },
};

export const CONTROL_EFFECTIVENESS_COLORS: any = {
    effectiveAmount: '#509BC8',
    ineffectiveAmount: '#A251A2',
    notRatedAmount: '#A3A5A8',
};
export const LINE_CHART_COLORS: any = {
    'Data Security': '#4D79FF',
    'Finance': '#E6B000',
    'Operations': '#44AB46',
    'Regulatory': '#FF8733',
};
export const RISK_LINE_CHART_COLORS: any = {
    'Residual Risk': '#4D79FF',
    'Inherent Risk': '#E6B000',
};

export const SCORE_LEVELS_BCOLORS: any = {
    VERY_LOW: {
        bgColor: '#CEF2CA',
        borderColor: '#CEF2CA',
    },
    LOW: {
        bgColor: '#D1FFCC',
        borderColor: '#2F8432',
    },
    MEDIUM: {
        bgColor: '#FFF6D9',
        borderColor: '#CC9C00',
    },
    HIGH: {
        bgColor: '#FFD6CC',
        borderColor: '#91240B',
    },
    VERY_HIGH: {
        bgColor: '#FFD6CC',
        borderColor: '#91240B',
    },
};

export const TESTING_LEVELS_COLORS: any = {
    TESTED: {
        bgColor: '#D1FFCC',
        borderColor: '#2F8432',
    },
    UNTESTED: {
        bgColor: '#FFF6D9',
        borderColor: '#CC9C00',
    },
    ERROR: {
        bgColor: '#FFD6CC',
        borderColor: '#91240B',
    },
};

export const TESTING_LEGEND_COLORS: any = {
    TESTED: '#59D159',
    UNTESTED: '#FFC300',
    ERROR: '#DA3510',
};

export enum TEST_FLOW_TYPE {
    Home = 'Home',
    Inventory = 'Inventory',
}
export const pageSizeOptions = [
    { label: 5, value: 5 },
    { label: 10, value: 10 },
    { label: 25, value: 25 },
    { label: 100, value: 100 },
];
