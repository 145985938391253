import { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const MARGIN = { bottom: 50, left: 50, right: 50, top: 30 };

type DataPoint = { x: string; y: number };
type Dataset = { name: string; data: DataPoint[]; color: string };

type LineChartProps = {
    width: number;
    height: number;
    datasets: Dataset[];
    range?: Date[];
    yAxisTickInterval?: number;
    yAxisLabel?: string;
};

const LineChart = ({ width, height, datasets, range = [], yAxisTickInterval = 5, yAxisLabel = '' }: LineChartProps) => {
    const axesRef = useRef(null);
    const boundsWidth = width - MARGIN.right - MARGIN.left;
    const boundsHeight = height - MARGIN.top - MARGIN.bottom;

    // Y axis
    const max = d3.max(datasets.flatMap((dataset) => dataset.data.map((d) => d.y)));
    const yScale = d3
        .scaleLinear()
        .domain([0, (max || 0) + 1]) // Add a buffer of 1 to the max value
        .range([boundsHeight, 0]);

    // X axis
    const customTimeParser = d3.timeParse('%Y-%m-%d');
    const allTimes: any = datasets.flatMap((dataset) => dataset.data.map((d) => customTimeParser(d.x)));
    const dateDomain = d3.extent(allTimes);
    const xScale = d3
        .scaleTime()
        .domain(range || dateDomain)
        .range([0, boundsWidth]);

    useEffect(() => {
        const svgElement = d3.select(axesRef.current);
        svgElement.selectAll('*').remove();

        // X Axis
        const xAxisGenerator = d3.axisBottom(xScale).tickFormat(d3.timeFormat('%b') as any);
        svgElement
            .append('g')
            .attr('transform', 'translate(0,' + boundsHeight + ')')
            .call(xAxisGenerator);

        // Y Axis
        const yAxisGenerator = d3.axisLeft(yScale).ticks(yAxisTickInterval);
        svgElement
            .append('text')
            .attr('transform', 'rotate(-90)')
            .attr('y', 0 - MARGIN.left)
            .attr('x', 0 - boundsHeight / 2)
            .attr('dy', '1em')
            .style('text-anchor', 'middle')
            .style('fill', '#656579')
            .text(yAxisLabel);

        svgElement.append('g').call(yAxisGenerator);

        // Add horizontal grid lines, skipping the line at y=0
        svgElement
            .selectAll('.grid-line')
            .data(yScale.ticks(yAxisTickInterval).filter((tick) => tick !== 0)) // Filter out the tick value at 0
            .enter()
            .append('line')
            .attr('class', 'grid-line')
            .attr('x1', 0)
            .attr('x2', boundsWidth)
            .attr('y1', (d) => yScale(d))
            .attr('y2', (d) => yScale(d))
            .attr('stroke', '#ccc')
            .attr('stroke-dasharray', '5,5');
    }, [xScale, yScale, boundsHeight, boundsWidth]);

    // Build the lines and points
    const lineBuilder = d3
        .line<DataPoint>()
        .x((d) => xScale(customTimeParser(d.x) as Date))
        .y((d) => yScale(d.y));

    return (
        <svg width={width} height={height}>
            <g
                width={boundsWidth}
                height={boundsHeight}
                transform={`translate(${[MARGIN.left, MARGIN.top].join(',')})`}
            >
                {datasets.map((dataset) => {
                    const linePath = lineBuilder(dataset.data);
                    return (
                        <g key={dataset.name}>
                            <path
                                d={linePath || undefined}
                                opacity={1}
                                stroke={dataset.color}
                                fill='none'
                                strokeWidth={2}
                            />
                            {dataset.data.map((d, i) => (
                                <circle
                                    key={i}
                                    cx={xScale(customTimeParser(d.x) as Date)}
                                    cy={yScale(d.y)}
                                    r={3}
                                    fill={dataset.color}
                                />
                            ))}
                        </g>
                    );
                })}
            </g>
            <g
                width={boundsWidth}
                height={boundsHeight}
                ref={axesRef}
                transform={`translate(${[MARGIN.left, MARGIN.top].join(',')})`}
            />
        </svg>
    );
};

export default LineChart;
